import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Integrations",
  "sort": 1,
  "title": "PHP",
  "subtitle": "Integrate Criipto Signatures with PHP to sign PDFs using MitID, Swedish BankID, Norwegian BankId or other eIDs."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/tree/master/Signing/php"
      }}>{`PHP example`}</a>{`
using `}<a parentName="p" {...{
        "href": "https://github.com/spawnia/sailor"
      }}>{`Sailor`}</a>{`
is available at `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/tree/master/Signing/php"
      }}>{`GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      